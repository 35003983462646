
import { Options, prop, Vue } from "vue-class-component";
import { PosterProduct, ParameterActive } from "@/domain/entities/Booking";
import MainLoading from "../loading/main-loading.vue";

class Props {
  products = prop<Array<PosterProduct>>({
    default: [],
    type: Array,
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean,
  });
  firstRender = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isExpandProduct = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isOnePack = prop<boolean>({
    default: false,
    type: Boolean,
  });
}

@Options({
  emits: ["setActive"],
  components: {
    MainLoading
  },
})
export default class MainProducts extends Vue.with(Props) {
  setActive(index: number, product: string) {
    const parameter: ParameterActive =  {
      index: index,
      product: product
    }
    this.$emit('setActive', parameter);
  }

  get dataProducts() {
    const threshold = this.isOnePack ? 4 : 3;
    const data: PosterProduct[] = []
    this.products.forEach((item: PosterProduct, index: number) => {
      if (this.isExpandProduct) {
        data.push(item)
      } else {
        if (index < threshold) {
          data.push(item)
        }
      }
    })

    return data;
  }
}
