
import { Options, Vue, prop } from "vue-class-component";
import ButtonBooking from "@/app/ui/components/button-list/index.vue";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import ModalErrorBalance from "@/app/ui/views/shipment/booking/component/modal-error-balance.vue";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
import {
  payloadOfThermalData,
  downloadFilePrn
} from "@/app/infrastructures/misc/UtilsThermal";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { ThermalReceiptData } from "@/domain/entities/Printer";

class Props {
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  bookingType = prop<string>({
    default: "",
    type: String
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  isPrintAble = prop<boolean>({
    default: false,
    type: Boolean
  });
  algoId = prop<string>({
    default: "",
    type: String
  });
  comodityCode = prop<string>({
    default: "",
    type: String
  });
  onBooking = prop<any>({
    type: Function
  });
}

@Options({
  components: {
    ButtonBooking,
    ModalErrorBalance
  }
})
export default class Book extends Vue.with(Props) {
  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }

  menus = [
    {
      title: "Booking, Print Nanti",
      icon: "check-circle-border"
    },
    {
      title: "Booking, Print Thermal Resi",
      icon: "printer"
    },
    {
      title: "Booking, Print Thermal Resi PDF",
      icon: "document-download-border"
    },
    {
      title: "Booking, Print Basic/Save to PDF",
      icon: "document-download-border"
    }
  ];

  menuPrint = [
    {
      title: "Print Thermal Resi",
      icon: "printer"
    },
    {
      title: "Print Thermal Resi PDF",
      icon: "document-download-border"
    },
    {
      title: "Print Basic/Save to PDF",
      icon: "document-download-border"
    }
  ];

  get shipmentList() {
    return BookingController.shipmentIdList.data;
  }

  get indexElement() {
    return ShipmentBookingController.indexElement;
  }

  async action(index: number) {
    if (
      this.isBalanceUnderLimitForPos &&
      prefixAlgoEstimationShow(
        this.shipmentList[this.indexElement].shipmentAlgoId
      )
    ) {
      this.balanceUnderLimit = true;
      return;
    }
    if (this.onBooking && this.isMigratingFromElexys) {
      const resultData = await this.onBooking();
      if (!resultData) {
        return;
      }
    }
    switch (index) {
      case 0:
        this.bookPrintLater();
        break;
      case 1:
        await this.bookPrintThermal();
        break;
      case 2:
        await this.bookPrintDoc("thermal-pdf");
        break;
      default:
        await this.bookPrintDoc("basic");
        break;
    }
  }

  actionPrint(index: number) {
    switch (index) {
      case 0:
        this.print("thermal");
        break;
      case 1:
        this.print("thermal-pdf");
        break;
      default:
        this.print("basic");
        break;
    }
  }

  bookPrintLater() {
    this.bookingLater();
  }

  bookingLater() {
    const events: any = {
      shipment: "shipment_booking_print_nanti_button_clicked",
      addAddress: "booking_tambah_alamat_print_nanti_button_clicked"
    };

    dataLayer(events[this.bookingType], {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    if (!BookingController.guardBookingSubmit) {
      BookingController.setGuardBookingSubmit(true);
      return ShipmentBookingController.addShipmentBooking(this.payload);
    }
  }

  get STTID() {
    return ShipmentBookingController.sttId;
  }

  async bookingAndPrintSaveDoc() {
    const payload: any = {
      sttManual: this.payload.sttManual,
      referenceExternal: this.payload.referenceExternal,
      productName: this.payload.productName,
      originCity: this.payload.originCity,
      originDistrict: this.payload.originDistrict,
      destinationCity: this.payload.destinationCity,
      destinationDistrict: this.payload.destinationDistrict,
      phoneSender: this.payload.phoneSender,
      phoneReceiver: this.payload.phoneReceiver,
      receiver:
        typeof this.payload.receiver === "object"
          ? this.payload.receiver.customerName
          : this.payload.receiver,
      sender:
        typeof this.payload.sender === "object"
          ? this.payload.sender.customerName
          : this.payload.sender,
      addressReceiver: this.payload.addressReceiver,
      addressSender: this.payload.addressSender,
      commodityCode:
        typeof this.payload.commodityCode === "object"
          ? this.payload.commodityCode.code
          : this.payload.commodityCode,
      insurance: this.payload.insurance,
      statusGoods: this.payload.statusGoods,
      estimationPrice: this.payload.estimationPrice,
      taxNumber: this.payload.taxNumber,
      isWoodpack: this.payload.isWoodpack,
      isCOD: this.payload.isCOD,
      isDFOD: this.payload.isDFOD,
      codHandling: this.payload.codHandling,
      isSaveSender: this.payload.isSaveSender,
      isSaveReceiver: this.payload.isSaveReceiver,
      sttPieces: this.payload.sttPieces,
      isDO: this.payload.isDO,
      isMixpack: this.payload.isMixpack,
      codAmount: this.payload.codAmount,
      codFee: this.payload.codFee,
      shipmentPackageId: this.payload.shipmentPackageId,
      shipmentId: this.payload.shipmentId,
      sttPiecePerPack: this.payload.sttPiecePerPack,
      sttNextCommodity: this.payload.sttNextCommodity,
      otherShipperTicketCode: this.payload.otherShipperTicketCode,
      postalCodeDestination: this.payload.postalCodeDestination,
      discountFavoritePercentage: this.payload.discountFavoritePercentage,
      sttWeightAttachFiles: this.payload.sttWeightAttachFiles,
      sttRecipientAddressType: this.payload.sttRecipientAddressType
    };
    if (!BookingController.guardBookingSubmit) {
      BookingController.setGuardBookingSubmit(true);
      return ShipmentBookingController.bookingAndPrintSavePdf(payload);
    }
  }

  async generatePrintData() {
    return BookingController.generatePrinData({
      stt: this.STTID,
      accountType:
        AccountController.accountData.account_type === "partner"
          ? AccountController.accountData.account_type_detail.type
          : AccountController.accountData.account_type
    });
  }

  async generatePrintDataExternal(sttId: number) {
    return BookingController.generatePrinData({
      stt: sttId,
      accountType:
        AccountController.accountData.account_type === "partner"
          ? AccountController.accountData.account_type_detail.type
          : AccountController.accountData.account_type
    });
  }

  get printData() {
    return BookingController.generatePrintData;
  }

  async bookPrintThermal() {
    const events: any = {
      shipment: "shipment_booking_print_thermal_button_clicked",
      addAddress: "booking_tambah_alamat_print_thermal_button_clicked"
    };

    dataLayer(events[this.bookingType], {}, [
      "timestamp",
      "userType",
      "userId"
    ]);

    const book = await this.bookingAndPrintSaveDoc();
    if (!book) {
      return;
    }
    const print = await this.generatePrintData();
    if (book && print) {
      const accountType = AccountController.accountData.account_type;
      const payload = payloadOfThermalData(this.printData, accountType).map(
        (item: ThermalReceiptData) => ({
          ...item,
          sttIsCodRetail:
            !this.printData.sttShipmentId &&
            !this.printData.sttClientId &&
            (accountType === "partner" || accountType === "internal") &&
            item.sttIsCod
        })
      );
      downloadFilePrn(payload, this.$i18n.locale);

      if (this.printData.sttProductType.toLowerCase() === "interpack") {
        const printedLoad: any = await import(
          "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
        );
        printedLoad.default.methods.directPrintInterpack(this.printData.sttId);
      }
    }
  }

  async bookPrintDoc(type: string) {
    const events: any = {
      shipment: {
        "thermal-pdf": "shipment_booking_print_thermal_pdf_button_clicked",
        basic: "shipment_booking_print_basic_button_clicked"
      },
      addAddress: {
        "thermal-pdf": "booking_tambah_alamat_print_thermal_pdf_button_clicked",
        basic: "booking_tambah_alamat_print_basic_button_clicked"
      }
    };

    dataLayer(events[this.bookingType][type], {}, [
      "timestamp",
      "userType",
      "userId"
    ]);

    const book = await this.bookingAndPrintSaveDoc();
    if (!book) {
      return;
    }

    if (book) {
      const sttId = ShipmentBookingController.sttId;
      const printedLoad: any = await import(
        "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
      );
      const printType: any = {
        "thermal-pdf": () =>
          printedLoad.default.methods.printBasic(
            sttId,
            0,
            this.$i18n.locale,
            true,
            true
          ),
        basic: () =>
          printedLoad.default.methods.printBasic(
            sttId,
            1,
            this.$i18n.locale,
            true
          )
      };

      printType[type]();
    }
  }

  async print(type: string) {
    const sttId = this.payload.sttId;
    if (sttId) {
      const book: any = await import(
        "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
      );
      const printType: any = {
        thermal: () =>
          book.default.methods.printThermal(sttId, this.$i18n.locale),
        "thermal-pdf": () =>
          book.default.methods.printBasic(
            sttId,
            0,
            this.$i18n.locale,
            false,
            true
          ),
        basic: () =>
          book.default.methods.printBasic(sttId, 1, this.$i18n.locale)
      };

      printType[type]();
    }
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    return (
      BalanceController.balanceData.walletBalance <
      this.dataProfile.limit_rule_min_balance
    );
  }
}
