import { AccountController } from "@/app/ui/controllers/AccountController";
import { PosterProduct } from "@/domain/entities/Booking";

const accountIsNonForeign = !AccountController.accountData.accountIsForeign;

export const productStyle: any = {
    sameday: {
        icon: "sameday",
        motto: "Paket sampai di hari yang sama",
        color: "#F09035",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#FFDD5A",
        }
    },
    bosspack: {
        icon: "bosspack",
        motto: "Pengiriman Prioritas",
        color: "#4f46a4",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#6257ba",
        }
    },
    onepack: {
        icon: "onepack",
        motto: "Pengiriman <br>Express",
        color: "#8e78db",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#e9deff",
        }
    },
    regpack: {
        icon: "regpack",
        motto: "Pengiriman <br>Regular",
        color: "#238bcc",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d9f0ff",
        }
    },
    jagopack: {
        icon: "jagopack",
        motto: "Pengiriman <br>Termurah",
        color: "#469c5e",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#e3fcef",
        }
    },
    bigpack: {
        icon: "bigpack",
        motto: "Pengiriman Paket Besar",
        color: "#cd4425",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#fcece7",
        }
    },
    jumbopack: {
        icon: "jumbopack",
        motto: "Pengiriman Paket Besar",
        color: "#a8ac7f",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#b9bd8c7f",
        }
    },
    interpack: {
        icon: "interpack",
        motto: accountIsNonForeign ? "Pengiriman <br>Internasional" : "International <br>Shipment",
        color: "#d64f71",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#f4dfe7",
        }
    },
    otopack150: {
        icon: "otopack-150",
        motto: "Pengiriman <br>Otomotif",
        color: "#f09035",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#fefae8",
        }
    },
    otopack250: {
        icon: "otopack-250",
        motto: "Pengiriman <br>Otomotif",
        color: "#f09035",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#fefae8",
        }
    }
}

export const productHighlight: PosterProduct = {
    name: "BOSSPACK",
    icon: "bosspack",
    motto: "Pengiriman Prioritas",
    price: "Rp-",
    sla: "-",
    color: "#a7a8aa",
    footer: {
        firstColor: "#ffffff",
        secondColor: "#a7a8aa",
    },
    discountAgentPrecentage: "-",
    discountAgentNominal: "-",
    statusCard: false,
    statusSelect: false,
    statusActive: false
}

export const productSameday: PosterProduct = {
    name: "SAMEDAY",
    icon: "sameday",
    motto: "Paket sampai di hari <br> yang sama",
    price: "Rp-",
    sla: "-",
    color: "#F09035",
    footer: {
        firstColor: "#ffffff",
        secondColor: "#FFDD5A",
    },
    discountAgentPrecentage: "-",
    discountAgentNominal: "-",
    statusCard: false,
    statusSelect: false,
    statusActive: false
}

export const onePackProducts: PosterProduct[] = [
    {
        name: "onepack",
        icon: "onepack",
        motto: "Pengiriman <br>Express",
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
    }
]

export const mainProducts: PosterProduct[] = [
    {
        name: "regpack",
        icon: "regpack",
        motto: "Pengiriman <br>Regular",
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
    },
    {
        name: "jagopack",
        icon: "jagopack",
        motto: "Pengiriman <br>Termurah",
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
    },
    {
        name: "jumbopack",
        icon: "jumbopack",
        motto: "Pengiriman Paket Besar",
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
    },
    {
        name: "bigpack",
        icon: "bigpack",
        motto: "Pengiriman Paket Besar",
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
    },
    {
        name: "interpack",
        icon: "interpack",
        motto: accountIsNonForeign ? "Pengiriman <br>Internasional" : "International <br>Shipment",
        price: accountIsNonForeign ? "Rp-" : "RM",
        sla: "-",
        color: accountIsNonForeign ? "#a7a8aa" : "#d64f71",
        footer: {
            firstColor: "#ffffff",
            secondColor: accountIsNonForeign ? "#d4d5d8" : "#f4dfe7",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: !accountIsNonForeign,
        statusSelect: false,
        statusActive: !accountIsNonForeign
    },
    {
        name: "otopack150",
        icon: "otopack-150",
        motto: "Pengiriman <br>Otomotif",
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
    },
    {
        name: "otopack250",
        icon: "otopack-250",
        motto: "Pengiriman <br>Otomotif",
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
    },
]

export const allProductsDefault: PosterProduct[] = [...onePackProducts, ...mainProducts];
export const allProductsWithoutOnePacks: PosterProduct[] = mainProducts;
