
import { Vue, prop, Options } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import { ConfigPriceWoodpackingController } from "@/app/ui/controllers/ConfigPriceWoodpackingController";
import {
  WoodpackingAvailableCities,
  WoodpackingData
} from "@/domain/entities/ConfigWoodpacking";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

class Props {
  modelValue = prop<boolean>({
    default: false,
    type: Boolean,
  });
  city = prop<string>({
    default: "",
    type: String,
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean,
  });
  eligibleAccess = prop<boolean>({
    default: false,
    type: Boolean,
  });
  eligibleAccessForShipment = prop<boolean>({
    default: false,
    type: Boolean,
  });
}

@Options({
  emits: ["setFocus"],
  components: {
    Checkbox,
  },
})
export default class Woodpacking extends Vue.with(Props) {
  value = this.modelValue;
  setValue() {
    this.$emit("update:modelValue", this.value);
    this.$emit("setFocus", true);
  }

  mounted() {
    this.getConfig();
  }

  isAllowWoodPacking = false;
  async getConfig() {
    const res: WoodpackingData = await ConfigPriceWoodpackingController.getDetailWoopacking({
        id: 3
    });

    this.isAllowWoodPacking = this.allowWodpacking(
      res.woodpackingAvailableCities
    );
  }

  allowWodpacking(data: any) {
    return !!data.find(
      (item: WoodpackingAvailableCities) =>
        item.cityCode === this.city || item.cityCode === "ALL"
    );
  }

  resetData() {
    this.value = false;
  }

  get permissionSchedule() {
    return FlagsMedusa.flag_booking_woodpacking_enable.isEnabled() && (this.eligibleAccess  || this.eligibleAccessForShipment);
  }
}
