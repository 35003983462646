
import { Options, Vue, prop } from "vue-class-component";

class Props {
  loading = prop<boolean>({
    default: false,
    type: Boolean,
  });
}
@Options({
  emits: ["unExpand"],
})
export default class LessProducts extends Vue.with(Props) {
  unExpandProduct() {
    this.$emit("unExpand");
  }
}
