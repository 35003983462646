import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full ml-2 pr-5 sm:pr-0" }
const _hoisted_2 = { class: "flex flex-col sm:flex sm:flex-row gap-x-4" }
const _hoisted_3 = { class: "font-semibold text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 5 }
const _hoisted_7 = {
  key: 2,
  class: "overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Search = _resolveComponent("Search")!
  const _component_advanced_filter = _resolveComponent("advanced-filter")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ModalErrorBalance = _resolveComponent("ModalErrorBalance")!
  const _component_DetailRejectPopup = _resolveComponent("DetailRejectPopup")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _withDirectives(_createVNode("div", {
        class: "fixed right-0 h-full z-20",
        style: 
        `background-color:rgba(0,0,0,0.3); top: 9rem; width: calc(100% - ${
          _ctx.isAdvancedFilterOpened && _ctx.isCollapseSidebar ? `4px` : `24px`
        });`
      
      }, null, 4), [
        [_vShow, _ctx.isAdvancedFilterOpened]
      ]),
      _createVNode(_component_Breadcrumb),
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.$route?.meta.name), 1),
            _createVNode(_component_Search, {
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.searchValue,
              class: "w-300px mb-1",
              placeholder: _ctx.$t('bookingShipment.list.searchStt')
            }, null, 8, ["onInput", "onClear", "value", "placeholder"]),
            (_ctx.isAbleToAdvanceFilter)
              ? (_openBlock(), _createBlock(_component_advanced_filter, {
                  key: 0,
                  isAddedStatus: _ctx.isPosChild || _ctx.isInternal,
                  "model-value": _ctx.advancedFilterData,
                  isPeriode: true,
                  isOriginDestination: true,
                  isClientPartner: true,
                  isStatus: true,
                  isProduct: _ctx.accountIsNonForeign,
                  isCOD: _ctx.accountIsNonForeign,
                  isPAD: _ctx.isEnableCodBooking,
                  isDFOD: "",
                  addSumFilter: _ctx.advancedFilterData.DFOD ? 1 : 0,
                  isAsuransi: true,
                  isDisabled: false,
                  clientPartnerPlaceholder: _ctx.$t('bookingShipment.placeholderSelect.SelectclientPartner'),
                  titleProductCustom: _ctx.$t('bookingShipment.list.filter.serviceType'),
                  isPhoneNumber: "",
                  onChange: _ctx.onChangeAdvancedFilter,
                  onOpened: _ctx.onOpenedAdvancedFilter,
                  onReset: _ctx.onResetAdvancedFilter
                }, null, 8, ["isAddedStatus", "model-value", "isProduct", "isCOD", "isPAD", "addSumFilter", "clientPartnerPlaceholder", "titleProductCustom", "onChange", "onOpened", "onReset"]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.permissionList.total === 1)
            ? (_openBlock(), _createBlock(_component_lp_button, {
                key: 0,
                onClick: _ctx.permissionList.action,
                title: _ctx.permissionList.title,
                textColor: "white",
                disabled: !_ctx.validationAccount
              }, null, 8, ["onClick", "title", "disabled"]))
            : (_ctx.permissionList.total > 1)
              ? (_openBlock(), _createBlock(_component_lp_button, {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = 
          event => {
            _ctx.hitGtmBookingClicked();
            _ctx.togglePanel(event);
          }
        ),
                  title: "Buat Booking",
                  textColor: "white",
                  icon: _ctx.panelVisible ? 'chevron-up-white' : 'chevron-down-white',
                  disabled: !_ctx.validationAccount
                }, null, 8, ["icon", "disabled"]))
              : _createCommentVNode("", true),
          _createVNode(_component_OverlayPanel, {
            ref: "op",
            appendTo: "body",
            style: {"width":"auto"}
          }, {
            default: _withCtx(() => [
              (_ctx.isCreateManualAble)
                ? (_openBlock(), _createBlock(_component_LpButton, {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = 
            event => {
              _ctx.goToCreate();
              _ctx.togglePanel(event);
            }
          ),
                    class: "w-60",
                    flat: "",
                    dense: "",
                    iconLeft: "document-add",
                    title: "Booking Retail"
                  }))
                : _createCommentVNode("", true),
              (_ctx.isCreateManualAble)
                ? (_openBlock(), _createBlock("hr", _hoisted_4))
                : _createCommentVNode("", true),
              (_ctx.isCreateForCorporateAble)
                ? (_openBlock(), _createBlock(_component_LpButton, {
                    key: 2,
                    onClick: _cache[3] || (_cache[3] = 
            event => {
              _ctx.goToCreateClientBook();
              _ctx.togglePanel(event);
            }
          ),
                    class: "w-60",
                    flat: "",
                    dense: "",
                    iconLeft: "booking-client",
                    title: "Booking Klien"
                  }))
                : _createCommentVNode("", true),
              (_ctx.isAbleToCreateShipmentId)
                ? (_openBlock(), _createBlock("hr", _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.isAbleToCreateShipmentId)
                ? (_openBlock(), _createBlock(_component_LpButton, {
                    key: 4,
                    onClick: _cache[4] || (_cache[4] = 
            event => {
              _ctx.goToCreateShipmentBook();
              _ctx.togglePanel(event);
            }
          ),
                    class: "w-60",
                    flat: "",
                    dense: "",
                    customClass: "text-left",
                    iconLeft: "scan-qrcode",
                    title: "Booking Aplikasi & Marketplace"
                  }))
                : _createCommentVNode("", true),
              (_ctx.isAbleToCreateInstantBooking)
                ? (_openBlock(), _createBlock("hr", _hoisted_6))
                : _createCommentVNode("", true),
              (_ctx.isAbleToCreateInstantBooking)
                ? (_openBlock(), _createBlock(_component_LpButton, {
                    key: 6,
                    onClick: _cache[5] || (_cache[5] = 
            event => {
              _ctx.goToInstantBooking();
              _ctx.togglePanel(event);
            }
          ),
                    class: "w-60",
                    flat: "",
                    dense: "",
                    iconLeft: "instant-booking",
                    title: "Booking Instant"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      (_ctx.isError && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            errorType: _ctx.errorCause,
            onTryAgain: _ctx.onRequest
          }, null, 8, ["errorType", "onTryAgain"]))
        : (_ctx.shipmentBooking.length === 0 && _ctx.firstRequest && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 1,
              data: "data Booking"
            }))
          : (_openBlock(), _createBlock("div", _hoisted_7, [
              _createVNode(_component_TableV2, {
                class: "my-2",
                columns: _ctx.columns,
                data: _ctx.shipmentBooking,
                loading: _ctx.isLoading,
                pagination: _ctx.pagination,
                "onUpdate:pagination": _cache[6] || (_cache[6] = ($event: any) => (_ctx.pagination = $event)),
                onRequest: _ctx.onRequest,
                groupOptions: _ctx.actionGroupOptions,
                onClick: _ctx.goToDetail,
                isDetailAble: _ctx.isDetailAble,
                groupAction: true,
                isRightFreeze: true,
                totalColumnFreeze: 1,
                "delete-tooltip": "Batalkan Booking",
                onDeleteItem: _ctx.onOpenPopupCancelBooking,
                isPinnedScrollBottom: "",
                paginationStyle: _ctx.paginationStyle
              }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "groupOptions", "onClick", "isDetailAble", "onDeleteItem", "paginationStyle"])
            ]))
    ]),
    (_ctx.balanceUnderLimit)
      ? (_openBlock(), _createBlock(_component_ModalErrorBalance, {
          key: 0,
          modelValue: _ctx.balanceUnderLimit,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.balanceUnderLimit = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.isRejectPopup)
      ? (_openBlock(), _createBlock(_component_DetailRejectPopup, {
          key: 1,
          "is-detail": true,
          isShow: _ctx.isRejectPopup,
          "onUpdate:isShow": _cache[8] || (_cache[8] = ($event: any) => (_ctx.isRejectPopup = $event)),
          sttNumber: _ctx.sttNumberSelected,
          onSuccess: _ctx.onRequest
        }, null, 8, ["isShow", "sttNumber", "onSuccess"]))
      : _createCommentVNode("", true)
  ], 64))
}