import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex flex-col items-center justify-center" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "text-10px text-gray-lp-300 mt-2" }
const _hoisted_5 = { class: "text-10px text-gray-lp-800" }
const _hoisted_6 = { class: "text-10px" }
const _hoisted_7 = { class: "text-10px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_loading = _resolveComponent("main-loading")!
  const _component_card = _resolveComponent("card")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataProducts, (product, index) => {
    return (_openBlock(), _createBlock(_component_card, {
      key: index,
      isShadow: false,
      class: `relative pt-3 px-2 ${!_ctx.firstRender ? 'cursor-pointer' : ''}`,
      customClass: `${product.statusSelect ? ' bg-pink-lp-500 border border-red-lp-1500' : 'border border-gray-lp-400'} `,
      style: {"height":"180px"},
      onClick: ($event: any) => (!_ctx.firstRender && !_ctx.isDisabled && product.statusActive ? _ctx.setActive(index, product.name) : () => {}),
      isDisabled: _ctx.isDisabled || !product.statusActive
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_main_loading, { key: 0 }))
          : (_openBlock(), _createBlock("div", _hoisted_1, [
              _createVNode("div", _hoisted_2, [
                _createVNode("img", {
                  class: "text-gray-lp-600",
                  src: 
            require(`@/app/ui/assets/svg/product-logo/${product.icon}-${product.statusCard && product.statusActive ? 'active' : 'inactive'}.svg`)
            ,
                  alt: "products-cards",
                  width: "100",
                  height: "18"
                }, null, 8, ["src"]),
                _withDirectives(_createVNode("div", {
                  class: `text-10px font-semibold mt-2 text-center`,
                  style: `color: ${product.color}`
                }, null, 4), [
                  [_directive_sanitize_html, product.motto]
                ]),
                _createVNode("div", _hoisted_3, [
                  _createVNode("p", _hoisted_4, _toDisplayString(product.statusCard ? product.price : _ctx.$t('bookingShipment.product.zeroCurrency')), 1),
                  _createVNode("p", _hoisted_5, _toDisplayString(product.statusCard ? product.sla : "-"), 1)
                ])
              ]),
              _createVNode("div", {
                class: "absolute bottom-0 left-0 top-12 w-full rounded-b-md text-center py-1 font-medium",
                style: `
            background-color: ${product.color}; 
            background: linear-gradient(180deg, ${product.footer.firstColor} -109.09%, ${product.footer.secondColor} 100%);
            color: ${product.color};
            margin-top: 80px;
            `
              }, [
                _createVNode("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.$t('bookingShipment.product.descAgent')) + " ", 1),
                  _createVNode("strong", null, _toDisplayString(product.statusCard ? product.discountAgentPrecentage : "-"), 1)
                ]),
                _createVNode("strong", _hoisted_7, _toDisplayString(product.statusCard ? product.discountAgentNominal : _ctx.$t('bookingShipment.product.zeroCurrency')), 1)
              ], 4)
            ]))
      ]),
      _: 2
    }, 1032, ["class", "customClass", "onClick", "isDisabled"]))
  }), 128))
}