
import { Options, Vue, prop } from "vue-class-component";
import Book from "./book.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import SelectReceiptLanguage from "@/app/ui/views/shipment/booking/create-booking/payment-detail/select-receipt-language.vue";
import { GetEstimationPrice } from "@/data/payload/api/BookingRequest";

class Props {
  isBooking = prop<boolean>({
    default: false
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  bookingType = prop<string>({
    default: "",
    type: String
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  isShowArrow = prop<boolean>({
    default: true,
    type: Boolean
  });
  isPrintAble = prop<boolean>({
    default: false,
    type: Boolean
  });
  algoId = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  emits:["onSelectProduct"],
  components: {
    SelectReceiptLanguage,
    Book
  }
})
export default class Uncalculation extends Vue.with(Props) {
  next() {
    if (this.indexElement === this.shipmentList.length - 1) {
      return false;
    }
    ShipmentBookingController.setIndexElement(this.indexElement + 1);
    this.$emit("onSelectProduct");
    dataLayer("shipment_next_detail_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  previous() {
    if (this.indexElement === 0) {
      return false;
    }
    ShipmentBookingController.setIndexElement(this.indexElement - 1);
    this.$emit("onSelectProduct");
    dataLayer("shipment_prev_detail_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  get shipmentList() {
    return BookingController.shipmentIdList.data;
  }

  get indexElement() {
    return ShipmentBookingController.indexElement;
  }

  updateEstimationPrice() {
    return this.getEstimationPrice();
  }

  async getEstimationPrice() {
    if (!this.isDisabled) {
      const piecesDelivery: any = [];
      this.payload.sttPieces.map((item: any) => {
        piecesDelivery.push({
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_length: item.stt_piece_length,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_width: item.stt_piece_width,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_height: item.stt_piece_height,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_gross_weight: item.stt_piece_gross_weight
        });
      });

      return BookingController.getEstimationPrice(new GetEstimationPrice({
        originId: this.payload.originDistrict,
        destinationId: this.payload.destinationDistrict,
        commodityId:
          typeof this.payload.commodityCode === "object"
            ? this.payload.commodityCode.id
            : this.payload.commodityId,
        productType: this.payload.productName,
        accountType:
          AccountController.accountData.account_type === "partner"
            ? "pos"
            : AccountController.accountData.account_type,
        accountRefId: AccountController.accountData.account_type_detail.id,
        goodsPrice: this.payload.estimationPrice,
        insuranceType: this.payload.insurance,
        isWoodpacking: this.payload.isWoodpack,
        isHaveTaxId: !!this.payload.taxNumber,
        pieces: piecesDelivery,
        isCod: false,
        isDfod: false,
        codHandling: this.payload.codHandling,
        codAmount: 0,
        isDisablePromo: false,
        promoAppliedTo: "shipment"
      }));
    }
  }

  get isInterpack() {
    return this.payload.productName === "INTERPACK";
  }
}
