import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row text-12px xxl:text-14px mt-4 space-x-4 items-center" }
const _hoisted_2 = { class: "p-field-checkbox mt-3 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataWrapper, {
      label: "Woodpacking",
      class: "w-1/2",
      optional: ""
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Checkbox, {
            id: "woodpacking",
            binary: true,
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
            disabled: _ctx.isDisabled,
            onChange: _ctx.setValue
          }, null, 8, ["modelValue", "disabled", "onChange"]),
          _createVNode("label", {
            class: "ml-2 text-14px",
            for: "woodpacking",
            style: `${_ctx.isDisabled ? `color:#9e9e9e` : ''}`
          }, _toDisplayString(_ctx.$t("bookingShipment.column.woodPacking")), 5)
        ])
      ]),
      _: 1
    })
  ], 512)), [
    [_vShow, _ctx.isAllowWoodPacking && _ctx.permissionSchedule]
  ])
}